
<script setup>
const menus = ref([
  {
    index: 0,
    title: "features_menus_title1",
    one: "features_menus_one1",
    two: "features_menus_two1",
    three: "features_menus_three1",
  },
  {
    index: 1,
    title: "features_menus_title2",
    one: "features_menus_one2",
    two: "features_menus_two2",
    three: "features_menus_three2",
  },
  {
    index: 2,
    title: "features_menus_title3",
    one: "features_menus_one3",
    two: "features_menus_two3",
    three: "features_menus_three3",
  },
  {
    index: 3,
    title: "features_menus_title4",
    one: "features_menus_one4",
    two: "features_menus_two4",
    three: "features_menus_three4",
  },
]);

const menu_index = ref(0);

const onPressTab = (index) => {
  menu_index.value = index;
};
</script>
  

<template>
  <div>
    <section class="home-features" id="features">
      <p class="text-title" data-anim="fadeIn" data-anim-delay="0">
        {{ $t("Features") }}
      </p>
      <div class="features-menu" data-anim="fadeIn" data-anim-delay="0">
        <span
          :class="menu_index == 0 ? 'menu-item active' : 'menu-item'"
          @click="onPressTab(0)"
          >{{ $t("Protection") }}</span
        >
        <span
          :class="menu_index == 1 ? 'menu-item active' : 'menu-item'"
          @click="onPressTab(1)"
          >{{ $t("homepage.technology") }}</span
        >
        <span
          :class="menu_index == 2 ? 'menu-item active' : 'menu-item'"
          @click="onPressTab(2)"
          >{{ $t("Privacy") }}</span
        >
        <span
          :class="menu_index == 3 ? 'menu-item active' : 'menu-item'"
          @click="onPressTab(3)"
          >{{ $t("homepage.security") }}</span
        >
      </div>
      <div class="features-main">
        <div
          class="features-item"
          v-for="item in menus"
          :key="item.index"
          :style="{ opacity: item.index === menu_index ? 1 : 0 }"
        >
          <p class="text-item-title" data-anim="fadeIn" data-anim-delay=".3">
            {{ $t("homepage." + item.title) }}
          </p>
          <div class="features-container">
            <p class="text-one" data-anim="fadeIn" data-anim-delay=".3">
              {{ $t("homepage." + item.one) }}
            </p>
            <p class="text-two" data-anim="fadeIn" data-anim-delay=".3">
              {{ $t("homepage." + item.two) }}
            </p>
            <p class="text-three" data-anim="fadeIn" data-anim-delay=".3">
              {{ $t("homepage." + item.three) }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.home-features {
  margin-top: 160px;
  display: flex;
  flex-direction: column;

  .text-title {
    font-weight: 400;
    font-size: 180px;
    line-height: 137px;
    color: #ffffff;
    margin-left: 46px;
    letter-spacing: -0.04em;
  }
  .features-menu {
    display: flex;
    flex-direction: row;
    margin: 80px 60px 0px 60px;
    border-top: solid 2px #ffffff;
    border-bottom: solid 2px #ffffff;
  }
  .features-main {
    min-height: 1000px;
    position: relative;
  }

  .features-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: opacity 0.7s ease;
  }
  .menu-item {
    font-weight: 700;
    font-size: 14px;
    line-height: 80px;
    letter-spacing: -0.02em;
    margin-right: 40px;

    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .menu-item:hover {
    color: #fbc34a;
  }
  .menu-item.active {
    color: #fbc34a;
  }
  .text-item-title {
    font-weight: 400;
    font-size: 80px;
    line-height: 80px;
    letter-spacing: -0.02em;
    color: #ffffff;

    margin-top: 80px;
    margin-left: 60px;
    max-width: 1100px;
  }
  .features-container {
    margin: 0px 60px 0px 60px;
    padding: 80px 0px 160px 0px;
    display: grid;
    grid-template-areas: "one two three";
    grid-template-columns: 25% 25% 50%;
  }
  .text-one {
    grid-area: one;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #a0a0a0;
    max-width: 185px;
  }
  .text-two {
    grid-area: two;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #a0a0a0;
    max-width: 185px;
  }
  .text-three {
    grid-area: three;
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #ffffff;

    max-width: 585px;
  }
}

@media (max-width: 1599px) {
  .home-features {
    margin-top: 140px;

    .text-title {
      font-size: 140px;
      line-height: 112px;
      margin-left: 57px;
    }
    .features-menu {
      margin: 40px 60px 0px 60px;
    }
    .features-main {
      min-height: 700px;
    }
    .menu-item {
      line-height: 80px;
      margin-right: 32px;
    }
    .text-item-title {
      font-size: 56px;
      line-height: 64px;

      margin-top: 60px;
      margin-left: 60px;
      max-width: 870px;
    }
    .features-container {
      margin: 0px 60px 0px 60px;
      padding: 60px 0px 140px 0px;
    }
    .text-one {
      max-width: 145px;
    }
    .text-two {
      max-width: 145px;
    }
    .text-three {
      font-size: 36px;
      line-height: 56px;

      max-width: 580px;
    }
  }
}
@media (max-width: 1279px) {
  .home-features {
    margin-top: 120px;

    .text-title {
      font-size: 120px;
      line-height: 120px;
      margin-left: 33px;
    }
    .features-menu {
      margin: 30px 40px 0px 40px;
    }

    .menu-item {
      line-height: 80px;
      margin-right: 30px;
    }
    .text-item-title {
      font-size: 56px;
      line-height: 60px;

      margin-top: 60px;
      margin-left: 40px;
      max-width: 830px;
    }
    .features-container {
      margin: 0px 40px 0px 40px;
      padding: 60px 0px 120px 0px;
    }
    .text-one {
      max-width: 120px;
    }
    .text-two {
      max-width: 120px;
    }
    .text-three {
      font-size: 32px;
      line-height: 48px;

      max-width: 480px;
    }
  }
}
@media (max-width: 1023px) {
  .home-features {
    margin-top: 80px;

    .text-title {
      font-size: 100px;
      line-height: 100px;
      margin-left: 45px;
    }
    .features-menu {
      margin: 30px 45px 0px 45px;
    }
    .features-main {
      min-height: 580px;
    }
    .menu-item {
      line-height: 60px;
      margin-right: 20px;
    }
    .text-item-title {
      font-size: 48px;
      line-height: 50px;

      margin-top: 60px;
      margin-left: 45px;
      max-width: 680px;
    }
    .features-container {
      margin: 0px 45px 0px 45px;
      padding: 40px 0px 100px 0px;
      grid-template-areas:
        "three three"
        "one two";
      grid-template-columns: 50% 50%;
    }
    .text-one {
      max-width: 170px;
      margin-top: 60px;
    }
    .text-two {
      max-width: 170px;
      margin-top: 60px;
    }
    .text-three {
      font-size: 24px;
      line-height: 32px;

      max-width: 680px;
    }
  }
}

@media (max-width: 767px) {
  .home-features {
    margin-top: 60px;

    .text-title {
      font-size: 48px;
      line-height: 50px;
      margin-left: 20px;
    }
    .features-menu {
      margin: 20px 20px 0px 20px;
      justify-content: space-between;
    }
    .features-main {
      min-height: 400px;
    }
    .menu-item {
      line-height: 58px;
      margin-right: 0px;

      font-size: 12px;
    }
    .text-item-title {
      font-size: 24px;
      line-height: 30px;

      margin-top: 40px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .features-container {
      margin: 0px 20px 0px 20px;
      padding: 30px 0px 100px 0px;
      grid-template-areas:
        "three"
        "one"
        "two";
      grid-template-columns: 100%;
    }
    .text-one {
      max-width: 800px;
      margin-top: 40px;
    }
    .text-two {
      max-width: 800px;
      margin-top: 20px;
    }
    .text-three {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

@media (max-width: 575px) {
  .home-features {
    margin-top: 60px;

    .features-main {
      min-height: 450px;
    }
  }
}

@media (max-width: 460px) {
  .home-features {
    margin-top: 60px;

    .features-main {
      min-height: 500px;
    }
  }
}

@media (max-width: 400px) {
  .home-features {
    margin-top: 60px;

    .features-main {
      min-height: 550px;
    }
  }
}

@media (max-width: 375px) {
  .home-features {
    margin-top: 60px;

    .features-main {
      min-height: 600px;
    }
  }
}

@media (max-width: 335px) {
  .home-features {
    margin-top: 60px;

    .features-main {
      min-height: 700px;
    }
  }
}
</style>
  